<template>
  <div>
    <b-row>
      <b-col>
        <div class="float-right mr-1">
          <b-button
            v-if="readOnly"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            :variant="'primary'"
            class="mr-1"
            :disabled="actionObj.IsLegalHold || (userType === 'Investigator' && Number(UID) !== Number(activityObj.UserID)) || (userType === 'Investigator' && ActionObj.ApprovedByManagerID)"
            @click.prevent="editSynopsis()"
          >
            Edit Summary
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="p-1">
        <div class="p-1">
          <vue-editor
            id="editor"
            v-model="Synopsis"
            class="pl-1 font-weight-normal"
            :editor-toolbar="customToolbar"
            :disabled="readOnly"
          />
        </div>
      </b-col>
    </b-row>
    <b-row
      v-if="!readOnly"
      class="pt-5"
    >
      <b-col>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="mr-2"
          type="reset"
          variant="danger"
          @click="resetForm()"
        >
          Cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="submit"
          variant="primary"
          @click="saveSynopsis()"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BButton, BCol, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import APIService from "@core/utils/APIService";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {mapGetters} from "vuex";
import {VueEditor} from "vue2-editor";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    VueEditor,
  },
  directives: {
    Ripple,
  },
  props: {
    activityObj: {
      type: Object,
      default: () => {},
    },
    actionObj: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      readOnly: true,
      userType: "",
      UID: "",
      Synopsis: "",

      customToolbar: [[{
        header: [false, 1, 2, 3, 4, 5, 6]
      }], ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],
        [{
          align: ""
        }, {
          align: "center"
        }, {
          align: "right"
        }, {
          align: "justify"
        }],
        [{
          list: "ordered"
        }, {
          list: "bullet"
        }], [{
          indent: "-1"
        }, {
          indent: "+1"
        }],
        [{
          color: []
        }, {
          background: []
        }],
        ["link"]
      ],
    }
  },
  computed: {
    ...mapGetters({
      getUserType: "auth/getUserType",
    }),
    ActionObj() {
      return this.actionObj
    }
  },
  mounted() {
    this.Synopsis = this.activityObj.Synopsis
    this.userType = localStorage.getItem("userType");
    this.UID = localStorage.getItem("UID");
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "InfoIcon",
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          }
      );
    },
    editSynopsis() {
      this.readOnly = false;
    },
    resetForm() {
      this.readOnly = true;
    },

    objToArr(obj) {
      return Object.keys(obj).map((key) => obj[key]);
    },

    saveSynopsis() {
      this.activityObj.Synopsis = null;
      const encoder = new TextEncoder();
      const postData = {
        activityId: this.activityObj.ActivityID,
        binarySynopsis: this.objToArr(encoder.encode(this.Synopsis))
      }
      apiService
          .post("activity/manager-summary", postData)
          .then(res => {
            if (res) {
              this.showToast("success", "top-center", 4000, "Activity update");
              this.readOnly = true;
              this.activityObj.Synopsis = this.Synopsis;
            }
          })
    },
  },
}
</script>

<style scoped>
.synopsis-block {
  border: 1px solid #d8d6de;
  border-radius: 0.5rem;
  min-height: 20rem;
}
</style>