<template>
  <div>
    <b-row>
      <b-col>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            :disabled="actionObj.IsLegalHold || (userType === 'Investigator' && Number(UID) !== Number(activityObj.UserID)) || ActionObj.ApprovedByManagerID"
            @click.prevent="addExpense()"
          >
            Add Expense
          </b-button>
        </div>
      </b-col>
      <b-col>
        <span style="line-height: 3rem">No alcohol related expenses without prior manager approval.</span>
      </b-col>
    </b-row>
    <!-- table -->
    <b-row class="p-1">
      <b-col class="border-top-dark min-height-10 rounded-sm">
        <b-row
          class="bg-light border-left-dark border-right-dark height-50 d-flex align-items-center"
          :class="{'border-bottom-dark': !Expenses.length}"
        >
          <b-col md="3">
            Date
          </b-col>
          <b-col md="5">
            Description
          </b-col>
          <b-col md="3">
            Amount
          </b-col>
          <b-col md="1">
            Action
          </b-col>
        </b-row>
        <b-row
          v-for="(item, index) of Expenses"
          :key="index"
          class="border-left-dark border-right-dark d-flex align-items-center"
          :class="{'border-bottom-dark': index + 1 === Expenses.length}"
        >
          <b-col>
            <b-row class="pt-1 border-top">
              <b-col md="3">
                <span class="text-nowrap">{{ item.ExpenseDate | dateFormat }}</span>
              </b-col>
              <b-col md="5">
                <span class="text-nowrap">{{ item.Description }}</span>
              </b-col>
              <b-col md="3">
                <span class="text-nowrap">$ {{ item.Amount }}</span>
              </b-col>
              <b-col md="1">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    :disabled="ActionObj.ApprovedByManagerID"
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item>
                      <span @click="editExpenses(item.ExpenseID)">Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="onLine">
                      <span @click="deleteExpenses(item.ExpenseID)">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-modal
      id="modal-delete-expense"
      ok-only
      ok-variant="danger"
      ok-title="Delete expense"
      modal-class="modal-danger"
      centered
      title="Confirm Delete expense"
      @ok="confirmDeleteExpense()"
    >
      <div v-if="deletedExpense !== null">
        You are about to delete this Expense.
        <small>Are you sure you want to do this?</small>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {BButton, BCol, BDropdown, BDropdownItem, BModal, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import APIService from "@core/utils/APIService";
import {mapGetters} from "vuex";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BModal,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  filters: {
    dateFormat(val) {
      const date = new Date(val);
      const options = {year: 'numeric', month: '2-digit', day: '2-digit'}
      if (date) return date.toLocaleDateString("en-US", options);
    },
  },
  props: {
    activityObj: {
      type: Object,
      default: () => {},
    },
    actionObj: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      deletedExpense: null,
      Expenses: [],
      userType: "",
      UID: "",

      onLine: navigator.onLine,
    }
  },
  computed: {
    ...mapGetters({
      getUserType: "auth/getUserType",
      getOnlineStatus: "offline/getOnlineStatus",
    }),
    ActionObj() {
      return this.actionObj
    }
  },
  watch: {
    getOnlineStatus(val) {
      this.onLine = val;
    },
  },
  mounted() {
    this.userType = localStorage.getItem("userType");
    this.UID = localStorage.getItem("UID");
  },
  async created() {
    try {
      this.getExpenses();
    } catch (err) {
      this.error = err;
    }
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    getExpenses() {
      apiService
          .get('activity/' + this.activityObj.ActivityID + '/expenses')
          .then(res => {
            this.Expenses = res.data;
          })
    },
    addExpense() {
      this.$router.push('/cases/add-expenses/' + this.activityObj.ActivityID)
    },
    editExpenses(val) {
      this.$router.push('/cases/edit-expenses/' + val)
    },
    deleteExpenses(val) {
      this.deletedExpense = val;
      this.$bvModal.show("modal-delete-expense");
    },
    confirmDeleteExpense() {
      apiService
          .delete("activity/expense/" + this.deletedExpense)
          .then(res => {
            if (res) {
              this.deletedExpense = null;
              this.showToast("success", "top-center", 4000, "Expense deleted");
              this.getExpenses();
            }
          })
    },
  },
}
</script>

<style scoped>

</style>